


































import { Component, Mixins } from 'vue-property-decorator';

import SplashScreen from '@/components/SplashScreen/SplashScreen.vue';
import NavMenu from '@/components/NavSidebar/NavSidebar.vue';
import BannerPromotions from '@/components/BannerPromotions/BannerPromotions.vue';
import BannerRenewPlan from '@/components/BannerRenewPlan/BannerRenewPlan.vue';
import FloatingButton from '@/components/FloatingButtons/index.vue';
import ModalWelcome from '@/components/Modal/ModalWelcome/ModalWelcome.vue';

import Layout from '@/mixins/Layout';

@Component({
  components: {
    SplashScreen,
    NavMenu,
    BannerPromotions,
    BannerRenewPlan,
    FloatingButton,
    ModalWelcome
  }
})
export default class DefaultLayout extends Mixins(Layout) {}
